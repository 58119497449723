import React, { useState, useEffect, Fragment, useRef } from "react";
import { Link } from "react-router-dom";
import user_api from "../../ApiCallExpress/user_api";
import globalStore from "../../shared/globalStore";
import shared from "../../shared/shareStore";
import { s3_bucket } from "../../config";

export default function Biglogo(props) {
  const apiURL = window.location.origin.includes("http://localhost:")
    ? "http://localhost:4000"
    : window.location.origin.replace("3000", "4000");

  const [logo, setLogo] = useState("");
  var link = props.link;
  var style = "";
  if (link === "/") {
    style = "flex justify-end ";
    //check if being used in a iframe
    if (window.location !== window.parent.location) {
      link = "";
    }
  } else if (link === "/classrooms") {
    style = "flex justify-center sm:pt-4 md:pt-  ";
  }

  function IfPartner() {
    if (window.location.href.toLowerCase().includes("/partner")) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    if (
      window.location.href.toLowerCase().includes("/partner")

      // || shared.getUser.
    ) {
      // const pid = window.location.pathname.includes("/partner")
      //   ? window.location.pathname.split("/")[4]
      //   : shared.getUser.login_partner.id;
      var pid = 0;
      pid = window.location.pathname.split("/")[4];

      user_api
        .get_partner(pid)
        .then((res) => {
          console.log(res);
          if (res.partnerLogo !== "") {
            setLogo(
              // apiURL +
              //   "/userExpress/get_partner_logo_file/?file_url=" +
              //   res.partnerLogo
              `https://${s3_bucket}.s3.amazonaws.com/${res.partnerLogo}`
            );
          }
        })
        .catch((error) => {
          console.log("error", error);
          // navigate("/signup");
        });
    } else if (globalStore.getState().session.user.login_partner) {
      setLogo(
        `https://${s3_bucket}.s3.amazonaws.com/` +
          globalStore.getState().session.user.login_partner.partnerLogo
      );
    }
  }, []);
  // console.log("logo", logo);

  // logo.url = apiURL + "/userExpress/get_partner_logo_file/?file_url=" + res.url;

  return (
    <div className={style}>
      <Link
        to={
          !window.location.href.toLowerCase().includes("/partner") ? link : ""
        }
        className="font-Gilroy-blk text-violet-100 text-5xl lg:text-7xl  text-center"
      >
        {!IfPartner() ? (
          <div>
            <p>WIZ</p>
            <p className="-mt-2 text-lg lg:text-2xl font-Gilroy-l text-center">
              Learner
            </p>
          </div>
        ) : IfPartner() && logo === "" ? (
          <div className=" text-transparent">
            <p>WIZ</p>
            <p className="-mt-2 text-lg lg:text-2xl font-Gilroy-l text-center">
              Learner
            </p>
          </div>
        ) : (
          <div>
            <img src={logo} alt="logo" className="w-40 lg:w-48" />
          </div>
        )}
      </Link>
    </div>
  );
}
