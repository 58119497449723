import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import color from "../../corlorConfig";
import PersonalInfo from "./personalInfo";
import GuardianInfo from "./guardianInfo";
import AreaOfInterest from "./areaOfInterest";
import PartnerCustomField from "./partnerCustomField";
import Logo from "../logo/biglogo.js";
import Confirmation from "./confirmation.js";
import Verify from "./verify.js";
import user_api from "../../ApiCallExpress/user_api.js";
import { useNavigate } from "react-router-dom";
import shared from "../../shared/shareStore.js";

export default function PartnerSignup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const CurrentSignupStep = useSelector(
    (state) => state.partnerSignup_store.CurrentSignupStep
  );
  useEffect(() => {
    const url = window.location.href.split("/")[3].toLowerCase();
    if (url === "partnersignup") {
      color("ThemeNeutral");
      // 2, 4
      const partnerCode = window.location.pathname.split("/")[2];
      const pid = window.location.pathname.split("/")[4];

      user_api
        .get_partner(pid)
        .then((res) => {
          // check if partner exist, if not redirect to login, else verify token
          if (res.hasOwnProperty("error")) {
            // navigate("/signup");
            window.top.location.href = "/signup";
          } else if (res.partnerCode !== partnerCode) {
            // navigate("/signup");
            window.top.location.href = "/signup";
          } else {
            // SET_SIGNUP_VALUE;
            dispatch({
              type: "SET_SIGNUP_VALUE",
              payload: {
                key: "partnerInfo",
                value: res,
              },
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
          // navigate("/signup");
          window.top.location.href = "/signup";
        });
    }
  }, []);
  useEffect(() => {
    // const user = globalStore.getState().session?.user;
    const user = shared.getUser();
    if (user.id) {
      navigate("/classrooms");
    } else {
      // window reload
      // window.location.reload();
    }
  }, [shared.getUser()]);
  return (
    <div className="m-4 md:m-8 px-4 md:px-8 py-4 md:py-8 rounded-xl bg-main">
      {/* logo section */}
      {/* <PartnerCustomField />  */}
      {/* PartnerCustomField will be finished in the future */}
      <div>
        <Logo link="/" />
      </div>
      <div className="">
        {CurrentSignupStep === 1 ? (
          <PersonalInfo />
        ) : CurrentSignupStep === 2 ? (
          <GuardianInfo />
        ) : CurrentSignupStep === 3 ? (
          <AreaOfInterest />
        ) : CurrentSignupStep === 4 ? (
          <Confirmation />
        ) : CurrentSignupStep === 5 ? (
          <Verify></Verify>
        ) : (
          <div></div>
        )}
      </div>
      {/* switch step section */}
    </div>
  );
}
