class Users {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.wiz_username = data.wiz_username;
    this.email = data.email;
    this.password = data.password;
    this.resetToken = data.resetToken;
    this.dp_url = data.dp_url;
    this.usertype = data.usertype;
    this.isWiz = data.isWiz;
    this.points = data.points;
    this.qr_code_url = data.qr_code_url;
    this.qr_code_limit = data.qr_code_limit;
    this.qr_code_latest_date = data.qr_code_latest_date;
    this.location = data.location;
    this.isMobile = data.isMobile;
    this.isActive = data.isActive;
  }
}

class Forgot_password {
  constructor(data) {
    this.resetToken = data.resetToken;
    this.email_result = data.email_result;
  }
}

class Forgot_username {
  constructor(data) {
    this.email_result = data.email_result;
  }
}

class Log_out {
  constructor(data) {
    this.status = data;
  }
}

class Create_post {
  constructor(data) {
    this.id = data.id;
    this.title = data.title;
    this.level = data.level;
    this.unit = data.unit;
    this.task = data.task;
    this.user_id = data.user_id;
    this.comment = data.comment;
    this.is_deleted = data.is_deleted;
    this.created = data.created;
    this.replies = data.replies;
    this.has_wizReply = data.has_wizReply;
  }
}

class Log_time_for_user {
  constructor(data) {
    this.username = data[0];
    this.week_counter = data[1];
    this.week_total = data[2];
    this.completed = data[3];
    this.starting = data[4];
    this.ending = data[5];
    //this.max_log_period= data[1][6]
  }
}

class Get_user_qr_source {
  constructor(data) {
    this.img_source = data;
  }
}

class Instructor_rating {
  constructor(data) {
    this.id = data.id;
    this.instructor_id = data.instructor_id;
    this.student_id = data.student_id;
    this.class_id = data.class_id;
    this.rating_code = data.rating_code;
    this.rating = data.rating;
    this.description = data.description;
    this.created = data.created;
    this.complete = data.complete;
    this.expired = data.expired;
  }
}

class Get_campaign_data {
  constructor(data) {
    this.id = data.id;
    this.category_id = data.category_id;
    this.interest_id = data.interest_id;
  }
}

class Submit_competition_data {
  constructor(data) {
    this.firstname = data.firstname;
    this.lastname = data.lastname;
    this.email = data.email;
  }
}

export default {
  Users,
  Forgot_password,
  Forgot_username,
  Log_out,
  Create_post,
  Log_time_for_user,
  Get_user_qr_source,
  Instructor_rating,
  Get_campaign_data,
  Submit_competition_data,
};
