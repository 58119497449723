import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import access_api from "../../ApiCallExpress/access_api.js";
import user_api from "../../ApiCallExpress/user_api.js";
import styles from "../../pages/userAccessPage/StylingForUseraccess.js";
import Logo from "../logo/biglogo.js";

export default function SignUp() {
  useEffect(() => {
    // document.title = "Wiz-SignUp";

    const title = document.querySelector("title");
    title.innerHTML = title.innerHTML + " - SignUp";
  }, []);
  const history = useNavigate();
  const [user, setUser] = useState({
    Token: "",
    Name: "",
    wiz_username: "",
    email: "",
    password: "",
    confirm_password: "",
  });

  const [inputError, setInputError] = useState({
    Token: "",
    Name: "",
    wiz_username: "",
    email: "",
    password: "",
    confirm_password: "",
  });

  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);

  var loadScreen = <progress className="progress max-w-sm "></progress>;

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };

  const validateInput = (e) => {
    let { name, value } = e.target;
    setInputError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "wiz_username":
          if (!value) {
            stateObj[name] = "Please enter Username.";
          }
          break;

        case "Name":
          if (!value) {
            stateObj[name] = "Please enter Name.";
          }
          break;

        case "Token":
          if (!value) {
            stateObj[name] = "Please enter a Token.";
          }
          break;

        case "email":
          //check if value is email or not
          if (!value) {
            stateObj[name] = "Please enter Email.";
          } else if (!value.includes("@") || !value.includes(".")) {
            stateObj[name] = "Please enter a valid Email.";
          }

          break;

        case "password":
          if (!value) {
            stateObj[name] = "Please enter Password.";
          } else if (user.confirm_password && value !== user.confirm_password) {
            stateObj["confirm_password"] =
              "Password and Confirm Password does not match.";
          } else if (value.length < 8) {
            stateObj[name] = "Password must be at least 8 characters.";
          } else if (value.length > 20) {
            stateObj[name] = "Password must be less than 20 characters.";
          } //have capital letter, number and special character
          else if (!/[A-Z]/.test(value)) {
            stateObj[name] =
              "Password must contain at least one capital letter.";
          } else if (!/[0-9]/.test(value)) {
            stateObj[name] = "Password must contain at least one number.";
          } else {
            stateObj["confirm_password"] = user.confirm_password
              ? ""
              : inputError.confirm_password;
          }
          break;

        case "confirm_password":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password.";
          } else if (user.password && value !== user.password) {
            stateObj[name] = "Password and Confirm Password does not match.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  var dp_url = "icon-robot.png";
  var usertype = "";
  var location = "";
  var is_mobile = false;

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      user.Token !== "" &&
      user.Name !== "" &&
      user.wiz_username !== "" &&
      user.email !== "" &&
      user.password !== "" &&
      user.confirm_password !== ""
    ) {
      return access_api
        .token_validation_check(user.Token)
        .then(async function (access_response) {
          console.log(access_response);
          if (access_response.hasOwnProperty("error")) {
            setMessage(access_response.error);
            return;
          } else {
            return user_api
              .signup(
                access_response.user_id,
                user.Name,
                user.wiz_username,
                user.email,
                user.password,
                dp_url,
                (usertype = access_response.tokentype),
                location,
                is_mobile,
                access_response.partner_id
              )
              .then(async function (user_response) {
                //console.log(user.password);
                if (typeof user_response.error !== "undefined") {
                  setMessage(user_response.error);
                  // //console.log(user_response.data);
                  return;
                } else {
                  return access_api
                    .token_join(user.Token, user_response.id)
                    .then(function (joinTokenTOUser) {
                      if (typeof joinTokenTOUser.error !== "undefined") {
                        setMessage(joinTokenTOUser.error);
                        return;
                      } else {
                        setMessage("Successfully Signed Up");
                        setLoading(true);
                        setTimeout(() => {
                          history("/");
                        }, 3000);
                      }
                    })
                    .catch((error) => {
                      setMessage("Token is redeemed already.");
                    });
                }
              })
              .catch((error) => {
                setMessage("Username already exist.");
              });
          }
        })
        .catch((error) => {
          // setMessage(error);
        });
    } else {
      setMessage("Please fill all the fields.");
    }
  };

  const handleMessage = (event) => {
    setMessage("");
  };

  var message_style;
  if (message === "Successfully Signed Up") {
    message_style = (
      <div>
        <div className="pt-2 h-12 flex max-w-sm alert alert-success">
          <div className="justify-start">
            <p className={styles.Atag_size_dynamic + " font-Gilroy-l"}>
              {message + " "}
            </p>
          </div>
          <button onClick={handleMessage} className="flex-none pt-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="stroke-current flex-shrink-0 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
        </div>
        {loading !== false && loadScreen}
      </div>
    );
  } else {
    message_style = (
      <div className="pt-2 h-12 flex max-w-sm alert alert-error">
        <div className="justify-start">
          <p className={styles.Atag_size_dynamic + " font-Gilroy-l"}>
            {message + " "}
          </p>
        </div>
        <button onClick={handleMessage} className="flex-none pt-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current flex-shrink-0 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </button>
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-center my-auto sm:px-8 md:px-16 lg:px-16 xl:px-64">
      <Logo link="/" />
      <p
        className={
          "text-left text-violet-100 sm:pb-2 md:pb-8 lg:pb-16 font-Gilroy-b" +
          styles.title_size_dynamic
        }
      >
        Get's started!
        <p className={styles.Atag_size_dynamic}>
          {message !== "" && message_style}
        </p>
      </p>
      <form className="flex flex-col" onSubmit={handleSubmit}>
        <div className="flex grid grid-cols-2 gap-4 ">
          <div className=" grid-row-2 pr-4">
            <div>
              <label className={styles.input_lable}>Token</label>
              <input
                className={styles.input_box}
                name="Token"
                type="text"
                onChange={onInputChange}
                onBlur={validateInput}
              />
            </div>

            <div className={styles.input_error}>
              {inputError.Token && <span className="">{inputError.Token}</span>}
            </div>
          </div>

          <div className="grid-row-2 pl-4">
            <div>
              <label className={styles.input_lable}>Name</label>
              <input
                className={styles.input_box}
                name="Name"
                type="text"
                onChange={onInputChange}
                onBlur={validateInput}
              />
            </div>
            <div className={styles.input_error}>
              {inputError.Name && <span className="">{inputError.Name}</span>}
            </div>
          </div>
        </div>

        <div className=" flex-col pt-4 grid-row-2">
          <div>
            <label for="username" className={styles.input_lable}>
              Username
            </label>
            <input
              className={styles.input_box + " bg-white"}
              type="username"
              name="wiz_username"
              // onChange={this.handleUsernameChange}
              // placeholder="username"

              onChange={onInputChange}
              onBlur={validateInput}
            />
          </div>
          <div className={styles.input_error}>
            {inputError.wiz_username && (
              <span className="">{inputError.wiz_username}</span>
            )}
          </div>
        </div>

        <div className="flex flex-col pt-4 grid-row-2">
          <div>
            <label for="username" className={styles.input_lable}>
              Email address
            </label>
            <input
              type="email"
              name="email"
              // onChange={this.handleUsernameChange}
              // placeholder="username"
              onChange={onInputChange}
              onBlur={validateInput}
              className={styles.input_box}
            />
          </div>
          <div className={styles.input_error}>
            {inputError.email && <span className="">{inputError.email}</span>}
          </div>
        </div>

        <div className="flex flex-col pt-4 grid-row-2">
          <div>
            <label for="password" className={styles.input_lable}>
              Password
            </label>
            <input
              type="password"
              name="password"
              // onChange={this.handlePasswordChange}
              // placeholder="Password"

              onChange={onInputChange}
              onBlur={validateInput}
              className={styles.input_box}
            />
          </div>
          <div className={styles.input_error}>
            {inputError.password && (
              <span className="">{inputError.password}</span>
            )}
          </div>
        </div>

        <div className="flex flex-col pt-4 grid-row-2">
          <div>
            <label for="password" className={styles.input_lable}>
              Confirm Password
            </label>
            <input
              type="password"
              name="confirm_password"
              // onChange={this.handlePasswordChange}
              // placeholder="Password"
              onChange={onInputChange}
              onBlur={validateInput}
              className={styles.input_box}
            />
            <div className={styles.input_error}>
              {inputError.confirm_password && (
                <span className="">{inputError.confirm_password}</span>
              )}
            </div>

            <p
              className={
                "text-dsblue-90 pt-2 text-right" + styles.Atag_size_dynamic
              }
            >
              Already a member?
              <a href="/" className="text-violet-90">
                {" "}
                Sign In
              </a>
            </p>
          </div>
        </div>
        <div className="flex justify-end pt-6">
          <button className={styles.btn_size_dynamic} type="submit">
            Create Account
          </button>
        </div>
      </form>

      <br />
      <br />
      <br />
      <div className="text-dsblue-100 flex justify-center items-center gap-3 pt-4">
        <p className="">By creating an account, you are agreeing to our </p>{" "}
        <a
          href="/public/termsandconditions"
          target="_blank"
          className="text-violet-100 hover:underline -m-0"
        >
          Terms and Conditions
        </a>
        <p className="">and</p>
        <a
          href="/public/privacypolicy"
          target="_blank"
          className="text-violet-100 hover:underline -m-0"
        >
          Privacy Policy
        </a>
      </div>
    </div>
  );
}
