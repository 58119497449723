import React, { useEffect, useRef, useState } from "react";
import MicroAppComp from "../../microAppComp/microAppComp";
import config from "../../config";
import shared from "../../shared/shareStore";
import actions from "../../shared/actions";
import { useDispatch, useSelector } from "react-redux";

const [minWidth, maxWidth, defaultWidth] = [1000, 5000, 0];
export default function Main() {
  const [currentOpenChat, setCurrentOpenChat] = useState(false);
  // const [width, setWidth] = useState(defaultWidth);
  // const chatWidth = useSelector((state) => state.chatStore.chatWidth);
  const [chatWidth, setWidth] = useState(defaultWidth);

  var origin = window.location.origin;
  return (
    <div>
      <div
        // className="grid grid-cols-5"
        className="flex  w-full"
      >
        <div className="w-full">
          <MicroAppComp
            name={config.wizrobotics_react_name}
            entry={
              config.dynamic
                ? origin + config.wizrobotics_react_entryBuild
                : config.wizrobotics_react_entryDev
            }
            shared={shared}
          />
        </div>
        <MicroAppComp
          name={config.wiz_chat_name}
          entry={
            config.dynamic
              ? origin + config.wiz_chat_entryBuild
              : config.wiz_chat_entryDev
          }
          shared={shared}
        />
      </div>
    </div>
  );
}
//  <div className="w-8 bg-violet-80 h-full  flex justify-center items-center rounded-lg">
//           <div
//             className="cursor-col-resize h-full w-1 flex flex-col gap-10 justify-center items-center"
//             onMouseDown={() => {
//               isResized.current = true;
//             }}
//           >
//             <p className="">
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 strokeWidth="1.5"
//                 stroke="currentColor"
//                 className="w-3 h-6"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
//                 />
//               </svg>
//             </p>
//             <p className="">
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 strokeWidth="1.5"
//                 stroke="currentColor"
//                 className="w-3 h-6"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
//                 />
//               </svg>
//             </p>
//           </div>
//           {/* transform -rotate-90 */}
//           {/* <button
//             className=" h-full"
//             onClick={() => {
//               actions.setGlobalState({ openChat: !currentOpenChat });
//               setWidth(minWidth);
//             }}
//           >
//             <p className="transform -rotate-90">Chat</p>
//           </button> */}
//         </div>
//         <div
//           className=" opacity-100 "
//           style={{ width: `${chatWidth / 16}rem` }}
//         >
//           <MicroAppComp
//             name={config.wiz_chat_name}
//             entry={config.wiz_chat_entry}
//             shared={shared}
//           />
//         </div>
