import { combineReducers } from "redux";

let CurrentSignupStep = function (state = 1, action) {
  switch (action.type) {
    case "SET_CURRENT_SIGNUP_STEP":
      return action.payload;
    default:
      return state;
  }
};

let signupValues = function (
  state = {
    name: "",
    birthdate: "",
    gender: "",
    phone: "",
    email: "",
    username: "",
    password: "",
    confirmPassword: "",
    //step 2
    guardianName: "",
    guardianEmail: "",
    guardianPhone: "",
    guardianAddress: {},
    partnerInfo: {},
    //step 3
    areaOfInterest: [],
    // step 4
    partnerCustomField: {},
  },
  action
) {
  switch (action.type) {
    case "SET_SIGNUP_VALUES":
      return { ...state, ...action.payload };

    case "SET_SIGNUP_VALUE":
      return { ...state, [action.payload.key]: action.payload.value };

    case "SET_USER_INFO":
      return {
        ...state,
        name: action.payload.name,
        birthdate: action.payload.birthdate,
        gender: action.payload.gender,
        email: action.payload.email,
        username: action.payload.username,
        password: action.payload.password,
        confirmPassword: action.payload.confirmPassword,
      };

    case "SET_GUARDIAN_INFO":
      return {
        ...state,
        guardianName: action.payload.guardianName,
        guardianEmail: action.payload.guardianEmail,
        guardianPhone: action.payload.guardianPhone,
      };

    case "SET_GUARDIAN_ADDRESS":
      return {
        ...state,
        guardianAddress: action.payload,
      };

    case "SET_PARTNER_CUSTOM_FIELD":
      return {
        ...state,
        partnerCustomField: {
          ...state.partnerCustomField,
          [action.payload.key]: action.payload.value,
        },
      };

    //example of payload
    // {
    //   key: "field1",
    //   value: "value1",
    // }

    //reset all values
    case "RESET_SIGNUP_VALUES":
      return {
        name: "",
        birthdate: "",
        gender: "",
        email: "",
        username: "",
        password: "",
        confirmPassword: "",
        //step 2
        guardianName: "",
        guardianEmail: "",
        guardianPhone: "",
        guardianAddress: "",
        partnerInfo: {},
        //step 3
        areaOfInterest: "",
        // step 4
        partnerCustomField: {},
      };

    default:
      return state;
  }
};

let partnerSignup_store = combineReducers({
  CurrentSignupStep,
  signupValues,
});

export default partnerSignup_store;
