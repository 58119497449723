class Access_token {
  constructor(data) {
    this.id = data.id;
    this.tokentype = data.tokentype;
    this.token = data.token;
    this.joined = data.joined;
    this.created = data.created;
    this.isMobile = data.isMobile;
    this.user_id = data.user_id;
    this.partner_id = data.partner_id;
  }
}

class Access_priviledge {
  constructor(data) {
    this.accessTokenId = data.accessTokenId;
    this.core = data.core;
    this.subject = data.subject;
    this.level = data.level;
    this.unit = data.unit;
  }
}
class Content_priviledge {
  constructor(data) {
    this.accessTokenId = data.accessTokenId;
    this.core_id = data.core_id;
    this.subject_id = data.subject_id;
    this.level_id = data.level_id;
    this.unit_id = data.unit_id;
  }
}

class Mission_acess_priviledge {
  constructor(data) {
    this.id = data.id;
    this.mission_id = data.mission_id;
    this.answer_access = data.answer_access;
    this.user_id = data.user_id;
    this.created = data.created;
  }
}

export default {
  Access_token,
  Content_priviledge,
  Mission_acess_priviledge,
  Access_priviledge,
};
