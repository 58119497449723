import "./App.css";
import React from "react";
import PubRoute from "./routes/route";
import { Provider } from "react-redux";
import globalStore from "./shared/globalStore";
import axios from "axios";
import testfavicon from "./Asset/img/allclass.png";
import user_api from "./ApiCallExpress/user_api";
import { s3_bucket } from "./config";

axios.defaults.withCredentials = true;

function App() {
  const apiURL = window.location.origin.includes("http://localhost:")
    ? "http://localhost:4000"
    : window.location.origin.replace("3000", "4000");

  const [title] = React.useState(document.querySelector("title"));
  const store = globalStore;
  React.useEffect(() => {
    // if url includes "partner"
    if (window.location.href.toLowerCase().includes("partner")) {
      // title.innerHTML = "test";
      user_api
        .get_partner(window.location.pathname.split("/")[4])
        .then((res) => {
          console.log(res);
          // change rel="apple-touch-icon" to use the testfavicon
          const link = document.querySelector("link[rel~='apple-touch-icon']");
          // link.href = testfavicon;
          link.href = `https://${s3_bucket}.s3.amazonaws.com/${res.partnerFavicon}`;

          //  rel = "icon" to use the testfavicon
          const link2 = document.querySelector("link[rel~='icon']");
          link2.href = `https://${s3_bucket}.s3.amazonaws.com/${res.partnerSmallLogo}`;

          title.innerHTML = res.partnerName;
        });
    } else {
      if (store.getState().session.user.login_partner) {
        // title.innerHTML = "test";
        title.innerHTML =
          store.getState().session.user.login_partner.partnerName;
        // change rel="apple-touch-icon" to use the testfavicon
        const link = document.querySelector("link[rel~='apple-touch-icon']");
        link.href = `https://${s3_bucket}.s3.amazonaws.com/${
          store.getState().session.user.login_partner.partnerFavicon
        }`;
        //  rel = "icon" to use the testfavicon
        const link2 = document.querySelector("link[rel~='icon']");
        link2.href =
          `https://${s3_bucket}.s3.amazonaws.com/` +
          store.getState().session.user.login_partner.partnerSmallLogo;
      } else {
        title.innerHTML = "Wiz";
      }
    }
  }, [title, globalStore.getState().session.user]);
  return (
    <Provider store={globalStore}>
      <div className="App">
        {/* <header className="main-header">
        <a href="/react">reactApp</a>
        <a href="/socketPOC">socketPOC</a>
        <a href="/combined-route">combined-routeApp</a>
      </header> */}
        <div id="subapp-viewport1"></div>
        <PubRoute />
      </div>
    </Provider>
  );
}

export default App;
