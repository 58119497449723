import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import user_api from "../../ApiCallExpress/user_api";
import Success from "../../Asset/img/success.png";
import { redirect } from "react-router-dom";

export default function Verify() {
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const signupValues = useSelector(
    (state) => state.partnerSignup_store.signupValues
  );
  const [verificationCode, setVerificationCode] = useState("");

  const [inputError, setInputError] = useState("");
  const [seconds, setSeconds] = useState(60);
  const [haveSent, setHaveSent] = useState(true);
  const [progress, setProgress] = useState(1);

  useEffect(() => {
    // Exit early if countdown is finished
    if (seconds <= 0) {
      setHaveSent(false);
      return;
    }

    // Set up the timer
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    // Clean up the timer
    return () => clearInterval(timer);
  }, [seconds]);

  useEffect(() => {
    if (progress === 3) {
      setTimeout(() => {
        // window.top.location.href = "/";
        // redirect to partner login using partner code and partner id
        const partnerCode = signupValues.partnerInfo.partnerCode;
        const pid = signupValues.partnerInfo.id;
        window.top.location.href = `/partnerLogin/${partnerCode}/pid/${pid}`;
      }, 3000);
    }
  }, [progress]);
  // Format the remaining time (e.g., “00:05:10” for 5 minutes and 10 seconds)
  const formatTime = (timeInSeconds) => {
    const seconds = (timeInSeconds % 60).toString().padStart(2, "0");
    return `${seconds}`;
  };

  const disableNext = () => {
    var result = false;
    // check if verification code is 6 digits
    if (verificationCode.length !== 6) {
      result = true;
    }
    return result;
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    // setIsSigningUp(true);

    await user_api
      .verify_verification_token(
        signupValues.username,
        signupValues.email,
        verificationCode
      )
      .then(async (res) => {
        setProgress(2);
        await user_api
          .user_signup_with_partner(signupValues)
          .then((res) => {
            setProgress(3);
            // }
          })
          .catch((error) => {
            console.log("error", error);
          });
      })
      .catch((error) => {
        setErrorMessage(error);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      });
  };

  const handleResend = () => {
    setHaveSent(true);
    setSeconds(60);
    user_api
      .send_verification_email(signupValues.username, signupValues.email)
      .then((res) => {
        if (res.hasOwnProperty("error")) {
          // setErrorMessage(res.error);
        } else {
          console.log("Resend Code");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  return (
    <div>
      {progress === 2 && (
        <div>
          {/* let user know we are submitting the signup  */}
          <p className="text-xl md:text-3xl font-Gilroy-b  text-center text-dsblue-100">
            We are Signing You Up
          </p>
          <p className="text-center text-dsblue-100 mt-8 font-Gilroy-m">
            Please wait while we sign you up. This may take a few seconds.
          </p>
          <div class=" flex flex-col rounded-xl my-10">
            <div class="flex flex-auto flex-col justify-center items-center p-4 md:p-5">
              <div class="flex justify-center">
                <div
                  class="animate-spin inline-block w-10 sm:w-16 h-10 sm:h-16 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"
                  role="status"
                  aria-label="loading"
                >
                  <span class="sr-only ">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {progress === 3 && (
        <div className="flex flex-col justify-center items-center">
          <p className="text-xl md:text-3xl font-Gilroy-b  text-center text-dsblue-100">
            Registration Successful!
          </p>
          <img src={Success} alt="success" className="w-2/3md:w-1/2 my-4 md:my-8" />
          <p className="text-center text-dsblue-100 mt-2 md:mt-8 font-Gilroy-m">
            You have been successfully registered. You are now logged in and
            will be redirected to the homepage shortly.
          </p>
          <div class=" flex flex-col rounded-xl my-2 md:my-10">
            <div class="flex flex-auto flex-col justify-center items-center p-4 md:p-5">
              <div class="flex justify-center">
                <div
                  class="animate-spin inline-block w-10 h-10  border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"
                  role="status"
                  aria-label="loading"
                >
                  <span class="sr-only ">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {progress === 1 && (
        <div>
          {/* Ask User to input verification code, include a resend button */}
          <div className="py-8 flex flex-col gap-3">
            <p className="text-xl md:text-3xl font-Gilroy-b  text-center text-dsblue-100">
              Verify Your Email Address
            </p>
            <p className="text-left text-dsblue-100 mt-8 font-Gilroy-m">
              We have sent a 6-digit verification code to your email address.
              Please enter the code below to verify your email and complete the
              registration process. Make sure to check your spam or junk folder.
            </p>
          </div>
          <div className="flex flex-col justify-start gap-8">
            <div className="">
              <p className="text-xl md:text-3xl font-Gilroy-m text-left text-dsblue-100">
                Enter Verification Code
              </p>
              <input
                className="border border-dsblue-100 rounded-md p-2 mt-4"
                type="text"
                placeholder="Enter 6-digit Code"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                onBlur={(e) => {
                  if (verificationCode.length !== 6) {
                    setInputError(
                      "Please enter a valid 6-digit verification code"
                    );
                  } else {
                    setInputError("");
                  }
                }}
              />
              <div
                className={
                  "text-red-600 font-Gilroy-l-itl bg-red-100 mt-2 pl-2 rounded-lg w-full md:w-1/6"
                }
              >
                {errorMessage && <span className="">{errorMessage}</span>}
              </div>
            </div>
            <div className="pt-4">
              <p className="text-xl md:text-3xl font-Gilroy-m text-left text-dsblue-100">
                Didn’t receive the code?
              </p>
              <button
                className={
                  formatTime(seconds) === "00" && !haveSent
                    ? "bg-dsblue-100 text-white rounded-xl p-2 py-4 mt-4 w-full md:w-1/3"
                    : "bg-dsblue-100 text-white rounded-xl p-2 py-4 mt-4 w-full md:w-1/3 grayscale cursor-not-allowed"
                }
                disabled={formatTime(seconds) !== "00" || haveSent}
                onClick={() => {
                  handleResend();
                }}
              >
                Resend Code
              </button>
              {formatTime(seconds) !== "00" && (
                <p className="text-left text-dsblue-80 mt-2">
                  You can resend the code in 60 seconds. ( {formatTime(seconds)}{" "}
                  seconds )
                </p>
              )}
            </div>
          </div>{" "}
          <div className="pt-24">
            {/* Next */}
            <div className="flex justify-between items-center ">
              <button
                type="button"
                onClick={() => {
                  dispatch({
                    type: "SET_CURRENT_SIGNUP_STEP",
                    payload: 1,
                  });
                }}
                className="border-2 border-violet-100 inline-flex justify-center py-2 px-6 shadow-sm text-sm font-medium rounded-md text-dsblue-100 bg-white hover:bg-dsblue-100 "
              >
                Back
              </button>
              <button
                onClick={handleSubmit}
                disabled={disableNext()}
                className={
                  disableNext()
                    ? "inline-flex justify-center py-2 px-6  shadow-sm text-sm rounded-md text-white bg-violet-100 hover:bg-dsblue-200  grayscale cursor-not-allowed"
                    : "inline-flex justify-center py-2 px-6  shadow-sm text-sm rounded-md text-white bg-violet-100 hover:bg-dsblue-200  "
                }
              >
                Verify
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
