import { sessionService } from "redux-react-session";
import globalStore from "./globalStore";

// make functions to get and set the store
export function getUser() {
  const userInfo = globalStore.getState().session.user;
  return userInfo;
}

export async function setUser(User) {
  await sessionService.saveSession(User);
  await sessionService.saveUser(User);
}

export async function deleteUser() {
  await sessionService.deleteSession();
  await sessionService.deleteUser();
}

//chat open close
export function setOpenChat(openChat) {
  console.log("setOpenChat", openChat);
  globalStore.dispatch({
    type: "SET_OPEN_CHAT_STATE",
    payload: openChat,
  });
}

// export function getOpenChat() {
//   return globalStore.getState().chatStore.openChat;
// }

// export function setChatWidth(width) {
//   globalStore.dispatch({
//     type: "SET_CHAT_WIDTH",
//     payload: width,
//   });
// }

// export function getChatWidth() {
//   return globalStore.getState().chatStore.chatWidth;
// }

const shared = {
  getUser,
  setUser,
  deleteUser,
  setOpenChat,
  // getOpenChat,
  // setChatWidth,
  // getChatWidth,
};

export default shared;
