import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import React from "react";
import { connect } from "react-redux";
import MicroAppComp from "../microAppComp/microAppComp";
import shared from "../shared/shareStore";
// import Login from "../pages/login/login";
import config from "../config";
import Main from "../layout/main/main";
import UserAccessPage from "../pages/userAccessPage/index";
import PrivateRoute from "./PrivateRoute";
import Login from "../component/login";
import PartnerSignup from "../component/partnerSignup/partnerSignup";

const pubRoutes = ({ authenticated, checked }, props) => {
  // get origin
  var origin = window.location.origin;
  return (
    <Router>
      {" "}
      {checked && (
        <Routes>
          <Route
            path="/"
            element={
              <div>
                <UserAccessPage name="login" />
              </div>
            }
          />
          <Route
            path="/login"
            element={
              <div>
                <UserAccessPage name="login" />
              </div>
            }
          />
          <Route
            path="/partnerLogin/:partner_token/pid/:partner_id"
            caseSensitive={false}
            element={
              <div>
                <Login />
              </div>
            }
          />
          <Route
            path="/partnerLogin/:partner_token/pid/:partner_id/token/:token"
            caseSensitive={false}
            element={
              <div>
                <Login />
              </div>
            }
          />

          <Route
            path="/signup"
            caseSensitive={false}
            element={<UserAccessPage name="signup" />}
          />

          <Route
            path="/partnerSignup/:partner_token/pid/:partner_id"
            caseSensitive={false}
            element={<PartnerSignup />}
          />

          <Route
            path="/forgotpassword"
            caseSensitive={false}
            element={<UserAccessPage name="forgotpassword" />}
          />
          <Route
            path="/forgotusername"
            caseSensitive={false}
            element={<UserAccessPage name="forgotusername" />}
          />
          <Route
            path="/resetpassword/:user_id/:token"
            caseSensitive={false}
            element={<UserAccessPage name="reset" />}
          />

          <Route
            //chat
            path="/chat"
            element={
              <div>
                <MicroAppComp
                  name={config.wiz_chat_name}
                  entry={
                    config.dynamic
                      ? origin + config.wiz_chat_entryBuild
                      : config.wiz_chat_entryDev
                  }
                  shared={shared}
                />
              </div>
            }
          />

          {/* //public pages, wiz_public */}
          <Route
            path="/public/*"
            element={
              <MicroAppComp
                name={config.wiz_public_name}
                entry={
                  config.dynamic
                    ? origin + config.wiz_public_entryBuild
                    : config.wiz_public_entryDev
                }
                // shared={shared}
              />
            }
          />

          <Route
            path="/*"
            element={
              <div>
                <PrivateRoute authenticated={authenticated}>
                  {/* <div className="grid grid-cols-5">
                    <div className=" col-span-4 ">
                      <MicroAppComp
                        name={config.wizrobotics_react_name}
                        entry={config.wizrobotics_react_entry}
                        shared={shared}
                      />
                    </div>
                    <div className=" opacity-100 ">
                      <MicroAppComp
                        name={config.wiz_chat_name}
                        entry={config.wiz_chat_entry}
                        shared={shared}
                      />
                    </div>
                  </div> */}
                  <Main />
                </PrivateRoute>
              </div>
            }
          />
        </Routes>
      )}
    </Router>
  );
};

const mapState = ({ session }) => ({
  checked: session.checked,
  authenticated: session.authenticated,
});
export default connect(mapState)(pubRoutes);
