var Atag_size_dynamic = " pt-2 lg:text-lg ";
var text_size_dynamic = " lg:text-2xl  text-xl";
var title_size_dynamic = " lg:text-3xl xl:text-5xl text-2xl";

var btn_size_dynamic =
  " bg-violet-80 hover:bg-violet-100 text-white font-bold py-3 px-6 rounded-lg lg:text-xl text-lg font-Gilroy-m";
var input_box =
  " border border-dsblue-90 rounded-lg w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline" +
  text_size_dynamic;
var input_lable = "pb-2 font-Gilroy-m text-dsblue-90" + text_size_dynamic;
var input_error = " text-red-600 font-Gilroy-l-itl bg-red-100 mt-2 pl-2 rounded-lg";

let styles = {
  Atag_size_dynamic: Atag_size_dynamic,
  text_size_dynamic: text_size_dynamic,
  title_size_dynamic: title_size_dynamic,
  btn_size_dynamic: btn_size_dynamic,
  input_box: input_box,
  input_lable: input_lable,
  input_error: input_error,
};

module.exports = styles;
