import { s3_bucket } from "../config";

class Core {
  constructor(data) {
    this.id = data.id;
    this.core = data.core;
    this.name = data.name;
    this.preview_mode = data.preview_mode;
    this.preview_url = `https://${s3_bucket}.s3.amazonaws.com/${data.preview_url}`;
  }
}

class Subjects {
  constructor(data) {
    this.id = data.id;
    this.subject = data.subject;
    this.name = data.name;
    this.description = data.description;
    this.preview_mode = data.preview_mode;
    this.core_id = new Core(data.core_id);
    this.preview_url = `https://${s3_bucket}.s3.amazonaws.com/${data.preview_url}`;
  }
}
class Level {
  constructor(data) {
    this.id = data.id;
    this.level = data.level;
    this.name = data.name;
    this.description = data.description;
    this.preview_url = `https://${s3_bucket}.s3.amazonaws.com/${data.preview_url}`;
    this.preview_mode = data.preview_mode;
    this.subject_id = data.subject_id;
    this.total_hours = data.total_hours;
    this.is_mobile = data.is_mobile;
    this.is_online_level = data.is_online_level;
  }
}

class Unit {
  constructor(data) {
    this.id = data.id;
    this.unit = data.unit;
    this.title = data.title;
    this.subtitle = data.subtitle;
    this.preview_url = `https://${s3_bucket}.s3.amazonaws.com/${data.preview_url}`;
    this.preview_mode = data.preview_mode;
    this.level_id = data.level_id;
  }
}

class Task {
  constructor(data) {
    ////console.log(data)
    this.id = data.id;
    this.task = data.task;
    this.title = data.title;

    this.url = data.url;
    if (this.url) {
      if (!this.url.includes("youtube.")) {
        this.url = `https://${s3_bucket}.s3.amazonaws.com/${data.url}`;
      }
    }
    this.preview_url = data.preview_url;
    if (this.preview_url) {
      if (!this.preview_url.includes("youtube.")) {
        this.preview_url = `https://${s3_bucket}.s3.amazonaws.com/${data.preview_url}`;
      }
    }
    this.answer_url = data.answer_url;
    if (this.answer_url) {
      if (!this.answer_url.includes("youtube.")) {
        this.answer_url = `https://${s3_bucket}.s3.amazonaws.com/${data.answer_url}`;
      }
    }

    this.downloadable = data.downloadable;
    this.slides = data.slides;
    this.task_type = data.task_type;
    this.instructor_only = data.instructor_only;
    this.recommended_time = data.recommended_time;
    this.preview_mode = data.preview_mode;
    this.instructor_id = data.instructor_id;
    this.unit_id = data.unit_id;
  }
}

class Question {
  constructor(data) {
    this.id = data.id;
    this.type = data.type;
    this.task_id = data.task_id;
    this.ordernumber = data.ordernumber;
    if (data.url) {
      this.url = `https://${s3_bucket}.s3.amazonaws.com/${data.url}`;
    }
    this.title = data.title;
    this.answer_a = data.answer_a;
    this.answer_b = data.answer_b;
    this.answer_c = data.answer_c;
    this.answer_d = data.answer_d;
    this.answer = data.answer;
    this.created = data.created;
    this.badgeType = data.badgeType;
    this.hint = data.hint;
  }
}

class Task_notes {
  constructor(data) {
    this.task_id = data.task_id;
    this.notes = data.notes;
  }
}

class Question_response {
  constructor(data) {
    this.id = data.id;
    this.question_id = data.question_id;
    this.user_id = data.user_id;
    this.response = data.response;
    this.attempted_time = data.attempted_time;
  }
}

class Score {
  constructor(data) {
    this.id = data.id;
    this.task_id = data.task_id;
    this.user_id = data.user_id;
    this.responses = data.responses;
    this.created = data.created;
    this.total_correct = data.total_correct;
    this.task_complete = data.task_complete;
  }
}

class Teacher_notes {
  constructor(data) {
    this.id = data.id;
    this.today_worked_on = data.today_worked_on;
    this.strengths = data.strengths;
    this.needs_improvement = data.needs_improvement;
    this.additional_references = data.additional_references;
    this.teacher_id = data.teacher_id;
    this.student_id = data.student_id;
    this.class_id = data.class_id;
    this.created = data.created;
  }
}

class Mission {
  constructor(data) {
    this.id = data.id;

    this.content = data.content;
    if (this.content) {
      if (!this.content.includes("youtube.")) {
        this.content = `https://${s3_bucket}.s3.amazonaws.com/${data.content}`;
      }
    }
    this.answer = data.answer;
    if (this.answer) {
      if (!this.answer.includes("youtube.")) {
        this.answer = `https://${s3_bucket}.s3.amazonaws.com/${data.answer}`;
      }
    }
    this.task_id = data.task_id;
    this.assignment_submission = data.assignment_submission;
    this.description_of_assignment = data.description_of_assignment;
    this.number_of_required_files = data.number_of_required_files;
    this.total_mark = data.total_mark;
    this.due_percent_in_course = data.due_percent_in_course;
  }
}

class Completed {
  constructor(data) {
    this.id = data.id;
    this.user_id = data.user_id;
    this.task_id = data.task_id;
    this.completed_date = data.completed_date;
  }
}

class Mobile_cost {
  constructor(data) {
    this.level_id = data.level_id;
    this.num_students = data.num_students;
    this.student_cost = data.student_cost;
  }
}

class Gift_shop {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.points = data.points;
    this.preview_url = `https://${s3_bucket}.s3.amazonaws.com/${data.preview_url}`;
    this.is_active = data.is_active;
    this.is_mobile = data.is_mobile;
    this.gift_type = data.gift_type;
    this.gift_card_value = data.gift_card_value;
    this.gift_card_currency = data.gift_card_currency;
  }
}

class Get_attempts {
  constructor(data) {
    this.task = data.task;
    this.attempts = data.attempts;
  }
}

class Core_progress {
  constructor(data) {
    this.core_ids = data.core_ids;
    this.configure_map();
  }
  configure_map() {
    for (var [key, value] of Object.entries(this.core_ids)) {
      this.core_ids[key]["core_info"] = new Core(value["core_info"]);
    }
  }
}

class Subject_progress {
  constructor(data) {
    this.subject_ids = data.subject_ids;
    this.configure_map();
  }
  configure_map() {
    for (var [key, value] of Object.entries(this.subject_ids)) {
      this.subject_ids[key]["subject_info"] = new Subjects(
        value["subject_info"]
      );
    }
  }
}

class Level_progress {
  constructor(data) {
    this.level_ids = data.level_ids;
    this.configure_map();
  }
  configure_map() {
    for (var [key, value] of Object.entries(this.level_ids)) {
      this.level_ids[key]["level_info"] = new Level(value["level_info"]);
    }
  }
}

class Units_progress {
  constructor(data) {
    this.unit_ids = data.unit_ids;
    this.configure_map();
  }
  configure_map() {
    for (var [key, value] of Object.entries(this.unit_ids)) {
      this.unit_ids[key]["unit_info"] = new Unit(value["unit_info"]);
    }
  }
}

class Tasks_progress {
  constructor(data) {
    this.task_ids = data.task_ids;
    this.configure_map();
    ////console.log("configure_map_final",this.task_ids)
  }

  configure_map() {
    // //console.log("configure_map",data.task_ids)
    for (var [key, value] of Object.entries(this.task_ids)) {
      this.task_ids[key]["task_info"] = new Task(value["task_info"]);
    }
  }
}

class Get_current_progress {
  constructor(data) {
    this.core = data.core;
    this.subject = data.subject;
    this.level = data.level;
    this.unit = data.unit;
  }
}

class Get_mission_tasks_in_unit {
  constructor(data) {
    this.task_ids = data.task_ids;
  }
}

class Get_teacher_note_for_students {
  constructor(data) {
    this.student_id = data;
  }
}

class Task_navigate {
  constructor(data) {
    if (data.prev_task) {
      this.prev_task = new Task(data.prev_task);
    } else {
      this.prev_task = data.prev_task;
    }
    if (data.next_task) {
      this.next_task = new Task(data.next_task);
    } else {
      this.next_task = data.next_task;
    }
  }
}

class Unit_tasks {
  constructor(data) {
    this.task_info = [];
    this.configure_map(data.task_info);
    this.mission_info = data.mission_info;
  }
  configure_map(data) {
    ////console.log("configure_map",data)
    for (var value of data) {
      this.task_info.push(new Task(value));
    }
  }
}

export default {
  Core,
  Subjects,
  Level,
  Unit,
  Task,
  Question,
  Task_notes,
  Question_response,
  Score,
  Teacher_notes,
  Mission,
  Completed,
  Mobile_cost,
  Gift_shop,
  Get_attempts,
  Core_progress,
  Subject_progress,
  Level_progress,
  Units_progress,
  Tasks_progress,
  Get_current_progress,
  Get_mission_tasks_in_unit,
  Get_teacher_note_for_students,
  Task_navigate,
  Unit_tasks,
};
