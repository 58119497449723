// const axios = require("axios");
import axios from "axios";
const apiURL = window.location.origin.includes("http://localhost:")
  ? "http://localhost:4000"
  : window.location.origin.replace("3000", "4000");
const {
  Access_token,
  Content_priviledge,
  Mission_acess_priviledge,
  Access_priviledge,
} = require("../models/access_models").default;

//function getToken(token) using axios to make get request to apiURL + "/token/"
function token_validation_check(token) {
  return new Promise(async (resolve, reject) => {
    await axios
      .get(`${apiURL}/accessExpress/token_validation_check/`, {
        params: { token: token },
      })
      .then((res) => {
        console.log("res", res);
        if (res.data.error !== undefined) {
          resolve(res.data);
        }
        resolve(new Access_token(res.data));
      })
      .catch((err) => {
        reject(err);
      });
  });

  // return fetch(`${apiURL}/accessExpress/token_validation_check/`, {
  //   method: "GET",
  //   headers: {
  //     // "Content-Type": "application/json",
  //     token: token,
  //   },
  // })
  //   .then((res) => {
  //     if (res.error !== undefined) {
  //       return res;
  //     }
  //     return new Access_token(res);
  //   })
  //   .catch((err) => {
  //     return err;
  //   });
}

// function generate_token(core_id, subject_id, level_id, is_online) using axios to make post request to apiURL + "/generate_token"
function generate_token(core_id, subject_id, level_id, is_online) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/generate_token`, {
        core_id,
        subject_id,
        level_id,
        is_online,
      })

      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

//function token_join(token, user_id) using axios to make post request to apiURL + "/token"

function token_join(token, user_id) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${apiURL}/accessExpress/token`, {
        token: token,
        user_id: user_id,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

//function get_redeem(user_id) using axios to make get request to apiURL + "/redeem"
function get_redeem(user_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiURL}/redeem/`, {
        user_id: user_id,
      })
      .then((res) => {
        if ("error" in res.data) {
          resolve(res.data);
        }
        //create a list to and using foreach loop store response data into and return it
        let list = [];
        res.data.forEach((element) => {
          list.push(new Content_priviledge(element));
        });
        resolve(list);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

//function put_redeem(user_id, token) using axios to make put request to apiURL + "/redeem"
function put_redeem(user_id, token) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${apiURL}/redeem`, {
        user_id,
        token,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

//function get_mission_access(user_id, mission_id) using axios to make get request to apiURL + "/get_mission_access"
function get_mission_access(user_id, mission_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiURL}/accessExpress/get_mission_access/`, {
        params: {
          mission_id: mission_id,
          user_id: user_id,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

//function get_multiple_mission_access(user_id, mission_ids) using axios to make get request to apiURL + "/get_multiple_mission_access"
function get_multiple_mission_access(user_id, mission_ids) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiURL}/get_multiple_mission_access/`, {
        params: {
          user_id: user_id,
          mission_ids: mission_ids,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

//function update_mission_permissions(user_id, mission_id, view_access, answer_access) using axios to make post request to apiURL + "/update_mission_permissions"
function update_mission_permissions(
  user_id,
  mission_id,
  view_access,
  answer_access
) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/update_mission_permissions`, {
        user_id: user_id,
        mission_id: mission_id,
        view_access: view_access,
        answer_access: answer_access,
      })
      .then((res) => {
        resolve(new Mission_acess_priviledge(res.data));
      })
      .catch((err) => {
        reject(err);
      });
  });
}

//function get_access_data(user_id) using axios to make get request to apiURL + "/get_access_data"
function get_access_data(user_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiURL}/get_access_data/`, {
        user_id: user_id,
      })
      .then((res) => {
        if ("error" in res.data || res.data === "False") {
          resolve(res.data);
        }
        resolve(new Access_token(res.data));
      })
      .catch((err) => {
        reject(err);
      });
  });
}

//function edit_access_token(token_id) using axios to make get request to apiURL + "/edit_access_token/"+token_id+"/"
function edit_access_token(token_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiURL}/edit_access_token/${token_id}/`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

//function token_generation(cores) 	using axios to make post request to apiURL + "/token_generation"
function token_generation(cores) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/token_generation`, {
        cores: cores,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

//function process_edit_token(token_type, numTokens=-1, numPrivilege, token_id, privilege_params_set, privilege_params_data=-1, token_location) using axios to make post request to apiURL + "/process_edit_token"
function process_edit_token(
  token_type,
  numTokens,
  numPrivilege,
  token_id,
  privilege_params_set,
  privilege_params_data,
  token_location
) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/process_edit_token`, {
        token_type: token_type,
        numTokens: numTokens,
        numPrivilege: numPrivilege,
        token_id: token_id,
        privilege_params_set: privilege_params_set,
        privilege_params_data: privilege_params_data,
        token_location: token_location,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

//function process_generate(token_type, numTokens, numPrivilege, token_id=-1, privilege_params_set, privilege_params_data,token_location) using axios to make post request to apiURL + "/process_generate"
function process_generate(
  token_type,
  numTokens,
  numPrivilege,
  token_id,
  privilege_params_set,
  privilege_params_data,
  token_location
) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/process_generate`, {
        token_type: token_type,
        numTokens: numTokens,
        numPrivilege: numPrivilege,
        token_id: token_id,
        privilege_params_set: privilege_params_set,
        privilege_params_data: privilege_params_data,
        token_location: token_location,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

//function get_token_data(token_id) using axios to make get request to apiURL + "/get_token_data/"+token_id+"/"
function get_token_data(token_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiURL}/get_token_data/${token_id}/`)

      .then((res) => {
        if (res.data.error) {
          resolve(res.data);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

//function delete_privilege(data_id) using axios to make get request to apiURL + "/delete_privilege/"
function delete_privilege(data_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiURL}/delete_privilege/`, {
        data_id: data_id,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

//function update_multiple_mission_permissions(user_ids, mission_ids, answer_access) using axios to make post request to apiURL + "/update_multiple_mission_permissions"
// function update_multiple_mission_permissions(
//   user_ids,
//   mission_ids,
//   answer_access
// ) {
//   return new Promise((resolve, reject) => {
//     axios
//       .post(`${apiURL}/update_multiple_mission_permissions/`, {
//         user_ids: user_ids,
//         mission_ids: mission_ids,
//         answer_access: answer_access,
//       })
//       .then((res) => {
//         resolve(res.data);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// }

// function get_access_token_data(token_id) using axios to make get request to apiURL + "/get_access_token_data/"+token_id+"/"
function get_access_token_data(token_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiURL}/get_access_token_data/${token_id}/`)
      .then((res) => {
        resolve(new Access_token(res.data));
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function core_subject_access_map(user_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiURL}/accessExpress/core_subject_access_map/`, {
        params: {
          user_id: user_id,
        },
      })
      .then((response) => {
        const map = new Map(Object.entries(response.data));
        resolve(map);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function core_subject_access(user_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiURL}/accessExpress/core_subject_access/`, {
        params: {
          user_id: user_id,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function TasksAccess(mission_ids, user_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiURL}/accessExpress/get_multiple_mission_access/`, {
        params: {
          mission_ids: mission_ids,
          user_id: user_id,
        },
      })
      .then((response) => {
        resolve(Object.keys(response.data.mission_ids));
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function TasksNAnswerAccess(mission_ids, user_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiURL}/accessExpress/get_multiple_mission_access/`, {
        params: {
          mission_ids: mission_ids,
          user_id: user_id,
        },
      })
      .then((response) => {
        resolve(Object.values(response.data.mission_ids));
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function update_multiple_mission_permissions(
  user_ids,
  mission_ids,
  view_access,
  answer_access
) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiURL}/accessExpress/update_multiple_mission_permissions/`, {
        user_ids: user_ids,
        mission_ids: mission_ids,
        view_access: view_access,
        answer_access: answer_access,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export default {
  token_validation_check: token_validation_check,
  token_join: token_join,
  get_redeem: get_redeem,
  put_redeem: put_redeem,
  core_subject_access: core_subject_access,
  get_mission_access: get_mission_access,
  get_multiple_mission_access: get_multiple_mission_access,
  update_mission_permissions: update_mission_permissions,
  get_access_data: get_access_data,
  edit_access_token: edit_access_token,
  token_generation: token_generation,
  process_generate: process_generate,
  process_edit_token: process_edit_token,
  get_token_data: get_token_data,
  delete_privilege: delete_privilege,
  // update_multiple_mission_permissions: update_multiple_mission_permissions,
  get_access_token_data: get_access_token_data,
  generate_token: generate_token,
  core_subject_access_map: core_subject_access_map,
  TasksAccess: TasksAccess,
  TasksNAnswerAccess: TasksNAnswerAccess,
  update_multiple_mission_permissions: update_multiple_mission_permissions,
};
