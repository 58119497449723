import React, { Component, useState, useEffect } from "react";
import Login from "../../component/login/index";
import Signup from "../../component/signup/index";
import ForgotPassword from "../../component/forgotPassword/index";
import ForgotUsername from "../../component/forgotUsername/index";
import ResetPassword from "../../component/resetPassword/resetPassword";
import Logo from "../../component/logo/biglogo";
import axios from "axios";

import {
  arduino0,
  chip0,
  chip1,
  coding0,
  coding1,
  robot0,
  robot1,
} from "../../Asset/img/userAccessPage";
import color from "../../corlorConfig";

export default function UserAccessPage(props) {
  var rightsideComponent;
  if (props.name === "login") {
    rightsideComponent = <Login />;
  } else if (props.name === "signup") {
    rightsideComponent = <Signup />;
  } else if (props.name == "forgotpassword") {
    rightsideComponent = <ForgotPassword />;
  } else if (props.name == "forgotusername") {
    rightsideComponent = <ForgotUsername />;
  } else if (props.name == "reset") {
    rightsideComponent = <ResetPassword />;
  }

  // const [image, setImage] = useState(arduino0);
  var imgList = [arduino0, chip0, chip1, coding0, coding1, robot0, robot1];

  useEffect(() => {
    //if site is being used by iframe then set the color to violet
    if (window.location === window.parent.location) {
      color("ThemeViolet");
    } else {
      color("ThemeNeutral");
    }
  }, []);
  return (
    <div className="w-full flex flex-wrap justify-center ">
      <div className="md:w-1/2 w-full flex flex-col shadow-2xl">
        <img
          className="md:h-screen md:w-screen w-0 h-0 object-cover "
          // src={checkIfImageIsWorking()}
          src={imgList[Math.floor(Math.random() * imgList.length)]}
          // src={
          //   "https://source.unsplash.com/random/portrait/?" + randomKeyWords()
          // }
        />
      </div>
      <div className="w-full md:w-1/2 flex flex-col px-5 lg:px-8 bg-white">
        {/* <Logo link="/"/> */}
        {rightsideComponent}
      </div>
    </div>
  );
}
