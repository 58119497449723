import React from "react";
import ReactDOM from "react-dom";
import {
  registerMicroApps,
  start,
  setDefaultMountApp,
  runAfterFirstMounted,
} from "qiankun";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import { ThemeProvider } from "@material-tailwind/react";

function render({ loading }) {
  const container = document.getElementById("root");
  ReactDOM.render(
    <React.StrictMode>
      {/* <ThemeProvider> */}
      <App />
      {/* </ThemeProvider> */}
    </React.StrictMode>,
    container
  );
}

render({ loading: true });

const loader = (loading) => render({ loading });

const apps = [
  // {
  //   name: "reactApp",
  //   entry: "//localhost:8585",
  //   activeRule: "/react",
  //   container: "#subapp-viewport1",
  //   loader,
  // },
  // {
  //   name: "socketPOC",
  //   entry: "//localhost:3000",
  //   activeRule: "/socketPOC",
  //   container: "#subapp-viewport1",
  //   loader,
  // },
];
registerMicroApps(apps, {
  beforeLoad: (app) => {
    console.log("before load app.name=====>>>>>", app.name);
  },
  beforeMount: [
    (app) => {
      console.log("[LifeCycle] before mount %c%s", "color: green;", app.name);
    },
  ],
  afterMount: [
    (app) => {
      console.log("[LifeCycle] after mount %c%s", "color: green;", app.name);
    },
  ],
  afterUnmount: [
    (app) => {
      console.log("[LifeCycle] after unmount %c%s", "color: green;", app.name);
    },
  ],
});

// setDefaultMountApp("/combined-route");

start({
  sandbox: {
    experimentalStyleIsolation: true,
  },
});

runAfterFirstMounted(() => {
  console.log("[MainApp] first app mounted");
});

reportWebVitals();
