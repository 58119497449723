import { combineReducers } from "redux";

// const [openChat, setOpenChat] = useState(false);
// const [selectedClass, setSelectedClass] = useState();
// const [selectedStudent, setSelectedStudent] = useState();
// const [inConversation, setInConversation] = useState(false);
// const cancelButtonRef = useRef(null);
// const [Textvalue, setTextValue] = useState({});
// const [ClassMessage, setClassMessage] = useState([]);
// const [haveSelectedStudent, setHaveSelectedStudent] = useState(false);
// const [list_of_classes, setListOfClasses] = useState([]);
// // const setListOfInstructors
// const [list_of_instructors, setListOfInstructors] = useState([]);
// const [list_of_students, setListOfStudents] = useState([]);
// const [isLoading, setIsLoading] = useState(true);
// const [loadingMessage, setLoadingMessage] = useState(false);
// const [scrollDirection, setScrollDirection] = useState("down");

let openChat = function (state = false, action) {
  switch (action.type) {
    case "SET_OPEN_CHAT_STATE":
      return action.payload;
    default:
      return state;
  }
};

let selectedClass = function (
  state = {
    id: 0,
    class_id: {
      id: 0,
      title: "",
      level_id: 0,
      code: "",
      created: "",
      booking_id: null,
      number_of_weeks: 0,
    },
    user_id: 0,
    joined: "",
    isactive: true,
    completed: false,
  },
  action
) {
  switch (action.type) {
    case "SET_SELECTED_CLASS_STATE":
      return action.payload;
    case "RESET_SELECTED_CLASS_STATE":
      return {
        id: 0,
        class_id: {
          id: 0,
          title: "",
          level_id: 0,
          code: "",
          created: "",
          booking_id: null,
          number_of_weeks: 0,
        },
        user_id: 0,
        joined: "",
        isactive: true,
        completed: false,
      };
    default:
      return state;
  }
};

let selectedStudent = function (state = null, action) {
  switch (action.type) {
    // case "SELECTED_STUDENT":
    case "SET_SELECTED_STUDENT_STATE":
      return action.payload;
    default:
      return state;
  }
};

let inConversation = function (state = false, action) {
  switch (action.type) {
    case "IN_CONVERSATION":
      return action.payload;
    default:
      return state;
  }
};

let Textvalue = function (state = {}, action) {
  switch (action.type) {
    case "TEXT_VALUE":
      return action.payload;
    default:
      return state;
  }
};

let ClassMessage = function (state = [], action) {
  switch (action.type) {
    case "CLASS_MESSAGE_ADD_ONE":
      return [...state, action.payload];
    case "CLASS_MESSAGE_ADD_MANY":
      return [...state, ...action.payload];
    case "CLASS_MESSAGE_REMOVE_ONE":
      return state.filter((message) => message.id !== action.payload);
    case "CLASS_MESSAGE_REMOVE_MANY":
      return state.filter((message) => !action.payload.includes(message.id));
    case "CLASS_MESSAGE":
      return action.payload;
    case "CLASS_MESSAGE_CLEAR":
      return [];

    default:
      return state;
  }
};

let haveSelectedStudent = function (state = false, action) {
  switch (action.type) {
    case "HAVE_SELECTED_STUDENT":
      return action.payload;
    default:
      return state;
  }
};

let list_of_classes = function (state = [], action) {
  switch (action.type) {
    case "LIST_OF_CLASSES":
      return action.payload;
    default:
      return state;
  }
};

let list_of_instructors = function (state = [], action) {
  switch (action.type) {
    case "LIST_OF_INSTRUCTORS":
      return action.payload;
    case "LIST_OF_INSTRUCTORS_ADD_ONE":
      return [...state, action.payload];

    default:
      return state;
  }
};

let list_of_students = function (state = [], action) {
  switch (action.type) {
    case "LIST_OF_STUDENTS":
      return action.payload;
    default:
      return state;
  }
};

let isLoading = function (state = true, action) {
  switch (action.type) {
    case "IS_LOADING":
      return action.payload;
    default:
      return state;
  }
};

let loadingMessage = function (state = false, action) {
  switch (action.type) {
    case "LOADING_MESSAGE":
      return action.payload;
    default:
      return state;
  }
};

let scrollDirection = function (state = "down", action) {
  switch (action.type) {
    case "SCROLL_DIRECTION":
      return action.payload;
    default:
      return state;
  }
};

let gotNewMessage = function (state = [], action) {
  switch (action.type) {
    case "GOT_NEW_MESSAGE":
      return action.payload;
    case "GOT_NEW_MESSAGE_ADD_ONE":
      return [...state, action.payload];
    case "GOT_NEW_MESSAGE_ADD_MANY":
      return [...state, ...action.payload];
    case "GOT_NEW_MESSAGE_REMOVE_ONE":
      return state.filter((message) => message.id !== action.payload);
    case "GOT_NEW_MESSAGE_REMOVE_MANY":
      return state.filter((message) => !action.payload.includes(message.id));
    case "GOT_NEW_MESSAGE_CLEAR":
      return [];
    default:
      return state;
  }
};

let socketio_jwt = function (state = "", action) {
  switch (action.type) {
    case "SOCKETIO_JWT":
      return action.payload;
    default:
      return state;
  }
};

let notification_class_ids = function (state = [], action) {
  switch (action.type) {
    case "NOTIFICATION_CLASS_IDS":
      return action.payload;
    case "NOTIFICATION_CLASS_IDS_ADD_ONE":
      return [...state, action.payload];
    default:
      return state;
  }
};

let notification_sender_ids = function (state = [], action) {
  switch (action.type) {
    case "NOTIFICATION_SENDER_IDS":
      return action.payload;
    case "NOTIFICATION_SENDER_IDS_ADD_ONE":
      return [...state, action.payload];
    case "NOTIFICATION_SENDER_IDS_ADD_MANY":
      return [...state, ...action.payload];
    case "NOTIFICATION_SENDER_IDS_REMOVE_ONE":
      return state.filter((id) => id !== action.payload);
    case "NOTIFICATION_SENDER_IDS_REMOVE_MANY":
      return state.filter((id) => !action.payload.includes(id));
    case "NOTIFICATION_SENDER_IDS_CLEAR":
      return [];
    default:
      return state;
  }
};

let chatWidth = function (state = 0, action) {
  switch (action.type) {
    case "SET_CHAT_WIDTH":
      return action.payload;
    default:
      return state;
  }
};

let chatStore = combineReducers({
  openChat,
  selectedClass,
  selectedStudent,
  inConversation,
  Textvalue,
  haveSelectedStudent,
  list_of_classes,
  list_of_instructors,
  list_of_students,
  isLoading,
  loadingMessage,
  scrollDirection,
  gotNewMessage,
  socketio_jwt,
  ClassMessage,
  notification_class_ids,
  notification_sender_ids,
  chatWidth,
});

export default chatStore;

// student_id: student.id,
// student_name: student.name,
// student_username: student.wiz_username,
// student_email: student.email,
// student_avatar: ProfileP,
// student_progress: 10,
// student_total: 15,
// student_level: curLvl,
// needtoMark: false, //?
// student_status: "Enrolled", //?
