import { useEffect, useRef } from "react";
import { loadMicroApp } from "qiankun";

const MicroApp = ({ name, entry, ...props }) => {
  const containerRef = useRef();
  const microAppRef = useRef(null);

  useEffect(() => {
    // microAppRef.current = loadMicroApp(
    //   {
    //     name,
    //     entry,
    //     container: containerRef.current,
    //     props,
    //   },
    //   {
    //     sandbox: {
    //       strictStyleIsolation: true,
    //       // experimentalStyleIsolation: true,
    //     },
    //   }
    // );
    fetch(entry, {
      method: "GET",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        // console.log(res);
        microAppRef.current = loadMicroApp(
          {
            name,
            entry,
            container: containerRef.current,
            props,
          },
          {
            sandbox: {
              strictStyleIsolation: true,
              // experimentalStyleIsolation: true,
            },
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      microAppRef.current?.unmount();
    };
  }, [name, entry, props]);

  useEffect(() => {
    if (microAppRef.current !== null && microAppRef.current.update) {
      microAppRef.current.update(props);
    }
  }, [props]);

  return <div ref={containerRef ? containerRef : null}></div>;
};

export default MicroApp;
