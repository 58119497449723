import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { input } from "@material-ui/core";

const config = require("../../config");

const apiKey = config.googleAPI;
const mapApiJs = "https://maps.googleapis.com/maps/api/js";
const geocodeJson = "https://maps.googleapis.com/maps/api/geocode/json";

// load google map api js

function Mod_LocationAutofill(props) {
  const dispatch = useDispatch();
  const signupValues = useSelector(
    (state) => state.partnerSignup_store.signupValues
  );
  const searchInput = useRef(null);
  const [address, setAddress] = useState({});
  const [inputError, setInputError] = useState("");
  useEffect(() => {
    if (signupValues.guardianAddress.street) {
      // searchInput.current.value =
      //   signupValues.guardianAddress.street +
      //   ", " +
      //   signupValues.guardianAddress.plain();
      searchInput.current.value =
        signupValues.guardianAddress.street +
        ", " +
        signupValues.guardianAddress.city +
        ", " +
        signupValues.guardianAddress.state +
        ", " +
        signupValues.guardianAddress.zip +
        ", " +
        signupValues.guardianAddress.country;
    } else {
      searchInput.current.value = "";
    }
  }, []);

  //   const [opVal, setOpVal] = useState(0);

  function loadAsyncScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      Object.assign(script, {
        type: "text/javascript",
        async: true,
        src,
      });
      script.addEventListener("load", () => resolve(script));
      document.head.appendChild(script);
    });
  }

  const extractAddress = (place) => {
    const address = {
      street: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      plain() {
        const street = this.street ? this.street + ", " : "";
        const city = this.city ? this.city + ", " : "";
        const zip = this.zip ? this.zip + ", " : "";
        const state = this.state ? this.state + ", " : "";
        return city + zip + state + this.country;
      },
    };

    if (!Array.isArray(place?.address_components)) {
      return address;
    }

    place.address_components.forEach((component) => {
      const types = component.types;
      const value = component.long_name;
      const shortName = component.short_name;

      if (types.includes("street_number")) {
        address.street = value;
      }
      if (types.includes("route")) {
        address.street += " " + value;
      }

      if (types.includes("locality")) {
        address.city = value;
      }

      if (types.includes("administrative_area_level_1")) {
        address.state = value;
      }

      if (types.includes("administrative_area_level_2")) {
        address.state = value;
      }

      if (types.includes("postal_code")) {
        address.zip = value;
      }

      if (types.includes("country")) {
        address.country = shortName;
      }
    });
    dispatch({
      // SET_GUARDIAN_ADDRESS
      type: "SET_GUARDIAN_ADDRESS",
      payload: address,
    });
    return address;
  };

  // init gmap script
  const initMapScript = () => {
    // if script already loaded
    if (window.google) {
      return Promise.resolve();
    }
    const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly&callback=Function.prototype`;
    return loadAsyncScript(src);
  };

  // do something on address change
  const onChangeAddress = (autocomplete) => {
    const place = autocomplete.getPlace();
    // console.log(place);
    setAddress(extractAddress(place));
  };

  // init autocomplete
  const initAutocomplete = () => {
    if (!searchInput.current) return;
    // console.log("initAutocomplete");
    // var options = {
    //   types: ["country"],
    //   //   componentRestrictions: { country: "us" },
    // };
    const autocomplete = new window.google.maps.places.Autocomplete(
      searchInput.current
    );

    autocomplete.setFields(["address_component", "geometry"]);
    autocomplete.addListener("place_changed", () => {
      onChangeAddress(autocomplete);
    });
  };

  // load map script after mounted
  useEffect(() => {
    // console.log("useEffect");
    initMapScript().then(() => initAutocomplete());
  }, []);

  return (
    <div>
      <input
        id="street"
        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md p-2"
        ref={searchInput}
        autoComplete="off"
        placeholder="Enter your address"
        onBlur={(e) => {
          console.log("onBlur", address);
          if (!searchInput.current.value) {
            setInputError("Please enter a valid address");
          } else {
            setInputError("");
          }
        }}
      />
      <div
        className={
          "text-red-600 font-Gilroy-l-itl bg-red-100 mt-2 pl-2 rounded-lg"
        }
      >
        {inputError !== "" && <span className="">{inputError}</span>}
      </div>
    </div>
  );
}

export default Mod_LocationAutofill;
