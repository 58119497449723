import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import user_api from "../../ApiCallExpress/user_api";
import Mod_LocationAutofill from "../LocationAutoFIll/Mod_locationAutoFill";

export default function GuardianInfo() {
  const [guardian, setGuardian] = useState({
    guardianName: "",
    guardianEmail: "",
    guardianPhone: "",
  });

  const [inputError, setInputError] = useState({
    guardianName: "",
    guardianEmail: "",
    guardianPhone: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const signupValues = useSelector(
    (state) => state.partnerSignup_store.signupValues
  );
  useEffect(() => {
    // console.log(signupValues);
    //if signupValues have value, then set user info
    if (signupValues.guardianName !== "") {
      setGuardian({
        guardianName: signupValues.guardianName,
        guardianEmail: signupValues.guardianEmail,
        guardianPhone: signupValues.guardianPhone,
      });
    }
  }, []);
  const onInputChange = (e) => {
    setErrorMessage("");
    const { name, value } = e.target;
    setGuardian((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };

  const validateInput = (e) => {
    let { name, value } = e.target;
    setInputError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        // all fields are not required but if one is entered, all should be valid
        case "guardianName":
          if (!value && (guardian.guardianEmail || guardian.guardianPhone)) {
            stateObj[name] = "Name is required";
          }
          break;
        case "guardianEmail":
          if (
            !/\S+@\S+\.\S+/.test(value) &&
            (guardian.guardianName || guardian.guardianPhone)
          ) {
            stateObj[name] = "Invalid email";
          } else if (
            !value &&
            (guardian.guardianName || guardian.guardianPhone)
          ) {
            stateObj[name] = "Email is required";
          }
          break;

        case "guardianPhone":
          value = value.replace(/[-\s\+\(]/g, "");
          if (
            !/^\d{10}$/.test(value) &&
            (guardian.guardianName || guardian.guardianEmail) &&
            value
          ) {
            stateObj[name] = "Invalid phone number";
          }
          break;
      }

      return stateObj;
    });
  };

  const disableNext = () => {
    var result = true;

    // all fields are not required but if one is entered, all should be valid
    if (
      guardian.guardianName !== "" ||
      guardian.guardianEmail !== "" ||
      guardian.guardianPhone !== ""
    ) {
      result =
        guardian.guardianName === "" ||
        guardian.guardianEmail === "" ||
        inputError.guardianName ||
        inputError.guardianEmail ||
        inputError.guardianPhone;
    } else {
      result = false;
    }
    return result;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // SET_CURRENT_SIGNUP_STEP;
    // user_api
    //   .validate_user_info(guardian.username, guardian.guardianEmail)
    //   .then((response) => {
    //     if (response.message) {
    dispatch({
      type: "SET_CURRENT_SIGNUP_STEP",
      payload: 3,
    });
    // SET_USER_INFO;
    dispatch({
      type: "SET_GUARDIAN_INFO",
      payload: guardian,
    });
    // }
    // console.log(response);
    // })
    // .catch((error) => {
    //   alert(error.error);
    //   console.log(error);
    //   setErrorMessage(error.error);
    // });
  };
  return (
    <div className="">
      <div className="py-8">
        <div className="flex justify-start items-center gap-3">
          <p className="text-2xl font-Gilroy-m text-left text-dsblue-100">
            Guardian Info
          </p>
          <p className="text-dsblue-60">( Optional )</p>
        </div>
        <p className="text-left text-dsblue-100">
          To ensure we can provide the best support and assistance, please
          provide detailed information about a parent or guardian.
        </p>
        {errorMessage && (
          <div className="py-1 px-4 mt-4 flex max-w-sm bg-red-200 rounded-xl text-dsblue-100">
            <div className="justify-start">
              <p className={"pt-2 lg:text-lg  font-Gilroy-l"}>
                {errorMessage + " "}
              </p>
            </div>
          </div>
        )}
      </div>

      <div>
        <div className="pt-4">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label
                  for="guardianName"
                  className="flex text-xl font-Gilroy-m text-dsblue-100 "
                >
                  Full Name
                  <p className="text-red-500 pl-1 text-sm">*</p>
                </label>
                <input
                  type="text"
                  name="guardianName"
                  id="guardianName"
                  value={guardian.guardianName}
                  placeholder="Enter your full name"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md p-2"
                  onChange={onInputChange}
                  onBlur={validateInput}
                />
                {/* text-red-600 font-Gilroy-l-itl bg-red-100 mt-2 pl-2 rounded-lg */}

                <div
                  className={
                    "text-red-600 font-Gilroy-l-itl bg-red-100 mt-2 pl-2 rounded-lg"
                  }
                >
                  {inputError.guardianName && (
                    <span className="">{inputError.guardianName}</span>
                  )}
                </div>
              </div>
              {/* phone */}
              <div>
                <label
                  for="guardianPhone"
                  className="flex text-xl font-Gilroy-m text-dsblue-100"
                >
                  Phone Number
                  {/* <p className="text-red-500 pl-1 text-sm">*</p> */}
                </label>
                <input
                  type="text"
                  name="guardianPhone"
                  id="guardianPhone"
                  value={guardian.guardianPhone}
                  placeholder="123-456-7890"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md p-2"
                  onChange={onInputChange}
                  onBlur={validateInput}
                />
                <div
                  className={
                    "text-red-600 font-Gilroy-l-itl bg-red-100 mt-2 pl-2 rounded-lg"
                  }
                >
                  {inputError.guardianPhone && (
                    <span className="">{inputError.guardianPhone}</span>
                  )}
                </div>
              </div>
              <div>
                <label
                  for="guardianEmail"
                  className="flex text-xl font-Gilroy-m text-dsblue-100"
                >
                  Email
                  <p className="text-red-500 pl-1 text-sm">*</p>
                </label>
                <input
                  type="guardianEmail"
                  name="guardianEmail"
                  id="guardianEmail"
                  value={guardian.guardianEmail}
                  placeholder="guardianEmail@email.com"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md p-2"
                  onChange={onInputChange}
                  onBlur={validateInput}
                />
                <div
                  className={
                    "text-red-600 font-Gilroy-l-itl bg-red-100 mt-2 pl-2 rounded-lg"
                  }
                >
                  {inputError.guardianEmail && (
                    <span className="">{inputError.guardianEmail}</span>
                  )}
                </div>
              </div>
              {/* Email */}
              <div className="">
                <label
                  for="guardianAddress"
                  className="flex text-xl font-Gilroy-m text-dsblue-100"
                >
                  Address
                  {/* <p className="text-red-500 pl-1 text-sm">*</p> */}
                  <p className="text-red-500 pl-1 text-sm">*</p>
                </label>
                {/* <input
                  type="text"
                  name="guardianAddress"
                  id="guardianAddress"
                  value={guardian.guardianAddress}
                  placeholder="Street, City, State, Zip"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md p-2"
                  onChange={onInputChange}
                  onBlur={validateInput}
                /> */}
                <Mod_LocationAutofill></Mod_LocationAutofill>
              </div>
            </div>

            <div className="pt-8">
              {/* Next */}
              <div className="flex justify-between items-center ">
                <button
                  type="button"
                  onClick={() => {
                    dispatch({
                      type: "SET_CURRENT_SIGNUP_STEP",
                      payload: 1,
                    });
                  }}
                  className="border-2 border-violet-100 inline-flex justify-center py-2 px-6 shadow-sm text-sm font-medium rounded-md text-dsblue-100 bg-white hover:bg-dsblue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Back
                </button>
                <button
                  type="submit"
                  disabled={disableNext()}
                  className={
                    disableNext()
                      ? "inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-violet-100 hover:bg-dsblue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 grayscale cursor-not-allowed"
                      : "inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-violet-100 hover:bg-dsblue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 "
                  }
                >
                  Next
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
