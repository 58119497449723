import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import user_api from "../../ApiCallExpress/user_api.js";
import styles from "./StylingForUseraccess.js";
import { connect } from "react-redux";
import { sessionService } from "redux-react-session";
import Logo from "../logo/biglogo.js";
import { useDispatch, useSelector } from "react-redux";

import globalStore from "../../shared/globalStore.js";
import actions from "../../shared/actions.js";
import shared from "../../shared/shareStore.js";
import color from "../../corlorConfig.js";
// import shareModel from "../../../../shared/shareStore.js";

const mapDispatchToProps = (dispatch) => {
  return {
    handleIsLoginFalse: () => dispatch({ type: "SET_IS_lOGIN_FALSE" }),
    handleIsLoginTrue: () => dispatch({ type: "SET_IS_lOGIN_TRUE" }),
  };
};

const mapStateToProps = (state) => {
  return {
    isLogin: state.main.isLogin,
    userDetail: state.session.user,
  };
};

function Login() {
  useEffect(() => {
    // document.title = "Wiz-Login";

    const title = document.querySelector("title");
    title.innerHTML = title.innerHTML + " - Login";
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // const [username, setUsername] = useState("");
  const [user, setUser] = useState({
    username: "",
    password: "",
  });
  const [inputError, setInputError] = useState({
    username: "",
    password: "",
  });

  // const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };

  // useEffect(() => {
  //   actions.onGlobalStateChange(async (state, prev) => {
  //     console.log("user", state);
  //     console.log("user", prev);
  //   });
  // }, []);
  // useEffect(() => {
  //   const user = globalStore.getState().session.user.id;
  //   // const user = shared.getUser();
  //   // console.log(user);
  //   if (globalStore.getState().session.user.id) {
  //     navigate("/classrooms");
  //   } else {
  //     // window reload
  //     // window.location.reload();
  //   }
  // }, [globalStore.getState().session.user]);
  useEffect(() => {
    const url = window.location.href.split("/")[3].toLowerCase();
    // if url contains partnerLogin
    if (url === "partnerlogin") {
      color("ThemeNeutral");
      // verifiy url "http://localhost:4000/partnerLogin/JSDKJH/pid/1

      const partnerCode = window.location.pathname.split("/")[2];
      const pid = window.location.pathname.split("/")[4];

      user_api
        .get_partner(pid)
        .then((res) => {
          // check if partner exist, if not redirect to login, else verify token
          if (res.hasOwnProperty("error")) {
            navigate("/login");
          } else if (res.partnerCode !== partnerCode) {
            navigate("/login");
          }
        })
        .catch((error) => {
          console.log("error", error);
          navigate("/login");
        });
    }
    // if url contains token
    if (window.location.href.includes("/token/")) {
      const token = window.location.href.split("/token/")[1];
      const partnerCode = window.location.pathname.split("/")[2];
      const pid = window.location.pathname.split("/")[4];

      user_api
        .verify_token(token)
        .then(async (response) => {
          await user_api
            .get_partner(pid)
            .then(async (partnerRes) => {
              // check if partner exist, if not redirect to login, else verify token
              if (partnerRes.hasOwnProperty("error")) {
                navigate("/login");
              } else if (partnerRes.partnerCode !== partnerCode) {
                navigate("/login");
              } else {
                const insertTime = async () => {
                  const newResponse = { ...response };
                  newResponse["login_time"] = new Date().getTime();
                  // insert login_partner
                  newResponse["login_partner"] = partnerRes;
                  return newResponse;
                };
                if (await insertTime().hasOwnProperty("error")) {
                  setErrorMessage(await insertTime().error);
                } else {
                  await actions.setGlobalState({
                    user: await insertTime(),
                  });
                  await shared.setUser(await insertTime());
                  navigate("/classrooms");
                }
              }
            })
            .catch((error) => {
              console.log("error", error);
              navigate("/login");
            });
        })
        .catch((error) => {
          setErrorMessage("Invalid token");
          // navigate("/login");
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        });
    }
  }, []);

  const validateInput = (e) => {
    let { name, value } = e.target;
    setInputError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "username":
          if (!value) {
            stateObj[name] = "Please enter Username.";
          }
          break;

        case "password":
          if (!value) {
            stateObj[name] = "Please enter Password.";
          }
          break;
      }

      return stateObj;
    });
  };

  // const handleUsernameChange = (event) => {
  //   setUser(event.target.value);
  // };
  // const handlePasswordChange = (event) => {
  //   setPassword(event.target.value);
  // };

  const handleErrorMessage = (event) => {
    setErrorMessage("");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    user_api
      .login(user.username, user.password)
      .then(async (response) => {
        // const { token } = response;
        if (response.hasOwnProperty("error")) {
          setErrorMessage(response.error);
        } else {
          const partnerlogin = window.location.href.split("/")[3].toLowerCase();
          if (partnerlogin === "partnerlogin") {
            if (
              // window.location.pathname.includes("/partnerLogin") &&
              // !window.location.pathname.includes("/token")
              partnerlogin === "partnerlogin" &&
              !window.location.pathname.includes("/token")
            ) {
              window.top.location.href =
                window.location.pathname + "/token/" + response.auth_token;
            }
          } else {
            //insert current time into response
            const insertTime = async () => {
              const newResponse = { ...response };
              newResponse["login_time"] = new Date().getTime();
              // newResponse["login_partner"] = partnerRes;
              
              return newResponse;
            };

            await actions.setGlobalState({
              user: await insertTime(),
            });
            await shared.setUser(await insertTime());
            if (window.location.href.includes("?next=")) {
              const next = window.location.href.split("?next=")[1];
              navigate(next);
            } else {
              navigate("/classrooms");
            }
          }

          // .catch((err) => console.error("error?", err));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  var message_style = (
    <div className="pb-2 px-2 flex max-w-sm bg-red-300 rounded-xl ">
      <div className="justify-start">
        <p
          className={
            styles.Atag_size_dynamic + " font-Gilroy-l text-dsblue-100"
          }
        >
          {errorMessage + " "}
        </p>
      </div>
      <button onClick={handleErrorMessage} className="flex-none pt-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="stroke-current flex-shrink-0 h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </button>
    </div>
  );

  return (
    <div
      className={
        window.location.pathname.toLowerCase().includes("/partner")
          ? "m-8 px-8 py-8 rounded-xl flex flex-col justify-center my-auto"
          : "flex flex-col justify-center my-auto pt-4 sm:px-0 md:px-16 lg:px-16 xl:px-64 "
      }
    >
      <Logo link="/" />
      <p
        className={
          "text-left sm:pb-2 md:pb-8 lg:pb-16 font-Gilroy-b text-dsblue-100 " +
          styles.title_size_dynamic
        }
      >
        Sign into your account
        <p className={styles.Atag_size_dynamic}>
          {errorMessage !== "" && message_style}
        </p>
      </p>
      <form className="flex flex-col" onSubmit={handleSubmit}>
        <div>
          <div className="flex flex-col pt-4">
            <label for="username" className={styles.input_lable}>
              Username
            </label>
            <input
              type="text"
              name="username"
              id="username"
              onChange={onInputChange}
              onBlur={validateInput}
              className={styles.input_box}
            />
          </div>
          <div className={styles.input_error}>
            {inputError.username && (
              <span className="">{inputError.username}</span>
            )}
          </div>
          <p className="text-right">
            <Link
              to="/forgotusername"
              className={
                "mt-4 text-right text-violet-90 w-auto" +
                styles.Atag_size_dynamic
              }
            >
              Forgot Username?
            </Link>
            {/* <a
              href="/forgotusername"
              // onClick={() => {
              //   navigate("/forgotusername");
              // }}
              className={
                "mt-4 text-right text-violet-90 w-auto" +
                styles.Atag_size_dynamic
              }
            >
              Forgot Username?
            </a> */}
          </p>
        </div>

        <div>
          <div className="flex flex-col pt-2">
            <label htmlFor="password" className={styles.input_lable}>
              Password
            </label>
            <input
              className={styles.input_box}
              type="password"
              name="password"
              id="password"
              onChange={onInputChange}
              onBlur={validateInput}
              // placeholder="Password"
            />
          </div>
          <div className={styles.input_error}>
            {inputError.password && (
              <span className="">{inputError.password}</span>
            )}
          </div>
          <p className="text-right">
            <a
              href="/forgotpassword"
              className={
                "mt-4 text-right text-violet-90 w-auto" +
                styles.Atag_size_dynamic
              }
            >
              Forgot Password?
            </a>
          </p>
        </div>
        <div className="flex justify-end pt-6">
          <button className={styles.btn_size_dynamic} type="submit">
            Login
          </button>
        </div>
      </form>
      <div className="text-center pt-12 pb-12 text-dsblue-90 flex justify-center items-center">
        <p className={styles.Atag_size_dynamic}>Don't have an account? </p>
        <a
          href="/signup"
          className={
            styles.Atag_size_dynamic + " underline font-Gilroy-m text-dsblue-90"
          }
        >
          Register here.
        </a>
      </div>
    </div>
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);
