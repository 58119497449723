import axios from "axios";
axios.default.withCredentials = true;
// const request = require('request-promise');
// var config = require("../config");
const SHA224 = require("sha224");
const apiURL = window.location.origin.includes("http://localhost:")
  ? "http://localhost:4000"
  : window.location.origin.replace("3000", "4000");

// const { promise_to_object_promise } = require("./api_utils");
const { Subjects } = require("../models/content_models").default;

async function get_all_partner_subjects(partner_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiURL}/contentExpress/get_all_partner_subjects`, {
        params: {
          partner_id: partner_id,
        },
      })
      .then((response) => {
        if (response.data.error) {
          reject(response.data.error);
        } else {
          let subjects = response.data.map((subject) => new Subjects(subject));
          resolve(subjects);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export default {
  get_all_partner_subjects,
};
