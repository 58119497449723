import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import user_api from "../../ApiCallExpress/user_api";

export default function PersonalInfo() {
  const [user, setUser] = useState({
    name: "",
    birthdate: "",
    gender: "",
    email: "",
    phone: "",
    username: "",
    password: "",
    confirmPassword: "",
  });

  const [inputError, setInputError] = useState({
    name: "",
    birthdate: "",
    gender: "",
    email: "",
    phone: "",
    username: "",
    password: "",
    confirmPassword: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const signupValues = useSelector(
    (state) => state.partnerSignup_store.signupValues
  );

  useEffect(() => {
    //if signupValues have value, then set user info
    if (signupValues.name !== "") {
      setUser({
        name: signupValues.name,
        birthdate: signupValues.birthdate,
        gender: signupValues.gender,
        email: signupValues.email,
        phone: signupValues.phone,
        username: signupValues.username,
        password: signupValues.password,
        confirmPassword: signupValues.confirmPassword,
      });
    }
  }, []);

  const onInputChange = (e) => {
    setErrorMessage("");
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };

  const validateInput = (e) => {
    let { name, value } = e.target;
    setInputError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "name":
          if (!value) {
            stateObj[name] = "Please enter Name.";
          }
          break;

        case "username":
          if (!value) {
            stateObj[name] = "Please enter Username.";
          }
          break;

        case "birthdate":
          if (!value) {
            stateObj[name] = "Please enter Birthdate.";
          } else if (new Date(value) > new Date()) {
            stateObj[name] = "Birthdate cannot be in the future.";
          }
          break;

        case "gender":
          if (!value) {
            stateObj[name] = "Please select your gender.";
          } else if (value === "") {
            stateObj[name] = "Please select your gender.";
          }
          break;

        case "phone":
          // situation where phone number is optional but if entered, it should be valid
          // if value inclde "-" or " " or "+" or "(", it should be removed
          value = value.replace(/[-\s\+\(]/g, "");
          if (value && !/^\d{10}$/.test(value)) {
            stateObj[name] = "Please enter valid Phone number.";
          }

          break;

        case "email":
          if (!value) {
            stateObj[name] = "Please enter Email.";
          } else if (!/\S+@\S+\.\S+/.test(value)) {
            stateObj[name] = "Please enter valid Email.";
          }
          break;

        case "password":
          //8 characters, including a number, lowercase, and uppercase letter and special character
          if (!value) {
            stateObj[name] = "Please enter Password.";
          } else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(value)) {
            stateObj[name] =
              "Password must be at least 8 characters, including a number, lowercase, and uppercase letter.";
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password.";
          } else if (value !== user.password) {
            stateObj[name] = "Password and Confirm Password must be same.";
          }
          break;
      }

      return stateObj;
    });
  };

  const disableNext = () => {
    return (
      inputError.name ||
      inputError.birthdate ||
      inputError.gender ||
      inputError.email ||
      inputError.phone ||
      inputError.username ||
      inputError.password ||
      inputError.confirmPassword ||
      user.name === "" ||
      user.birthdate === "" ||
      user.gender === "" ||
      user.email === "" ||
      user.username === "" ||
      user.password === "" ||
      user.confirmPassword === ""
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // SET_CURRENT_SIGNUP_STEP;
    user_api
      .validate_user_info(user.username, user.email)
      .then((response) => {
        if (response) {
          dispatch({
            type: "SET_CURRENT_SIGNUP_STEP",
            payload: 2,
          });
          // SET_USER_INFO;
          dispatch({
            type: "SET_USER_INFO",
            payload: user,
          });
        }
        // console.log(response);
      })
      .catch((error) => {
        alert(error);
        console.log(error);
        setErrorMessage(error);
      });
  };

  return (
    <div className="">
      <div className="py-8">
        <p className="text-2xl font-Gilroy-m text-left text-dsblue-100">
          Get Started with {signupValues.partnerInfo?.partnerName}
        </p>
        <p className="text-left text-dsblue-100">
          This form is intended for users who are interested in learning more
          about {signupValues.partnerInfo?.partnerName}. If you are under the
          age of 13, please ask a parent or guardian to complete this form.
        </p>
        {errorMessage && (
          <div className="py-1 px-4 mt-4 flex max-w-sm bg-red-200 rounded-xl text-dsblue-100">
            <div className="justify-start">
              <p className={"pt-2 lg:text-lg  font-Gilroy-l"}>
                {errorMessage + " "}
              </p>
            </div>
          </div>
        )}
      </div>

      <div>
        <p className="text-2xl font-Gilroy-m text-left text-dsblue-100">
          Personal Info
        </p>
        <div className="pt-4">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label
                  for="name"
                  className="flex text-xl font-Gilroy-m text-dsblue-100 "
                >
                  Full Name
                  <p className="text-red-500 pl-1 text-sm">*</p>
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={user.name}
                  placeholder="Enter your full name"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md p-2"
                  onChange={onInputChange}
                  onBlur={validateInput}
                />
                {/* text-red-600 font-Gilroy-l-itl bg-red-100 mt-2 pl-2 rounded-lg */}

                <div
                  className={
                    "text-red-600 font-Gilroy-l-itl bg-red-100 mt-2 pl-2 rounded-lg"
                  }
                >
                  {inputError.name && (
                    <span className="">{inputError.name}</span>
                  )}
                </div>
              </div>
              <div>
                <label
                  for="birthdate"
                  className="flex text-xl font-Gilroy-m text-dsblue-100"
                >
                  Birthdate
                  <p className="text-red-500 pl-1 text-sm">*</p>
                </label>
                <input
                  type="date"
                  name="birthdate"
                  id="birthdate"
                  value={user.birthdate}
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md p-2"
                  onChange={onInputChange}
                  onBlur={validateInput}
                />
                <div
                  className={
                    "text-red-600 font-Gilroy-l-itl bg-red-100 mt-2 pl-2 rounded-lg"
                  }
                >
                  {inputError.birthdate && (
                    <span className="">{inputError.birthdate}</span>
                  )}
                </div>
              </div>
              {/* gender */}
              <div>
                <label
                  for="gender"
                  className="flex text-xl font-Gilroy-m text-dsblue-100"
                >
                  Gender
                  <p className="text-red-500 pl-1 text-sm">*</p>
                </label>
                <select
                  name="gender"
                  id="gender"
                  className="py-3 px-4 w-full rounded-lg text-sm disabled:opacity-50 disabled:pointer-events-none border-r-8 border-white"
                  onChange={onInputChange}
                  onBlur={validateInput}
                  value={user.gender}
                >
                  <option value="">Select your gender</option>
                  <option value={"Male"}>Male</option>
                  <option value={"Female"}>Female</option>
                  <option value={"Other"}>Other</option>
                </select>

                <div
                  className={
                    "text-red-600 font-Gilroy-l-itl bg-red-100 mt-2 pl-2 rounded-lg"
                  }
                >
                  {inputError.gender && (
                    <span className="">{inputError.gender}</span>
                  )}
                </div>
              </div>
              {/* phone */}
              <div>
                <label
                  for="phone"
                  className="flex text-xl font-Gilroy-m text-dsblue-100"
                >
                  Phone
                  {/* <p className="text-red-500 pl-1 text-sm">*</p> */}
                </label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  value={user.phone}
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md p-2"
                  onChange={onInputChange}
                  onBlur={validateInput}
                />
                <div
                  className={
                    "text-red-600 font-Gilroy-l-itl bg-red-100 mt-2 pl-2 rounded-lg"
                  }
                >
                  {inputError.phone && (
                    <span className="">{inputError.phone}</span>
                  )}
                </div>
              </div>
              <div>
                <label
                  for="email"
                  className="flex text-xl font-Gilroy-m text-dsblue-100"
                >
                  Email
                  <p className="text-red-500 pl-1 text-sm">*</p>
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={user.email}
                  placeholder="example@email.com"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md p-2"
                  onChange={onInputChange}
                  onBlur={validateInput}
                />
                <div
                  className={
                    "text-red-600 font-Gilroy-l-itl bg-red-100 mt-2 pl-2 rounded-lg"
                  }
                >
                  {inputError.email && (
                    <span className="">{inputError.email}</span>
                  )}
                </div>
              </div>
              <div>
                <label
                  for="username"
                  className="flex text-xl font-Gilroy-m text-dsblue-100"
                >
                  Username
                  <p className="text-red-500 pl-1 text-sm">*</p>
                </label>
                <input
                  type="text"
                  name="username"
                  id="username"
                  value={user.username}
                  placeholder="Enter your username"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md p-2"
                  onChange={onInputChange}
                  onBlur={validateInput}
                />
                <div
                  className={
                    "text-red-600 font-Gilroy-l-itl bg-red-100 mt-2 pl-2 rounded-lg"
                  }
                >
                  {inputError.username && (
                    <span className="">{inputError.username}</span>
                  )}
                </div>
              </div>
              <div>
                <label
                  for="password"
                  className="flex text-xl font-Gilroy-m text-dsblue-100"
                >
                  Password
                  <p className="text-red-500 pl-1 text-sm">*</p>
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  value={user.password}
                  placeholder="Enter your password"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md p-2"
                  onChange={onInputChange}
                  onBlur={validateInput}
                />
                <label className="text-dsblue-60 text-sm ">
                  Must be at least 8 characters, including a number, lowercase,
                  and uppercase letter
                </label>
                <div
                  className={
                    "text-red-600 font-Gilroy-l-itl bg-red-100 mt-2 pl-2 rounded-lg"
                  }
                >
                  {inputError.password && (
                    <span className="">{inputError.password}</span>
                  )}
                </div>
              </div>
              <div>
                <label
                  for="confirmPassword"
                  className="flex text-xl font-Gilroy-m text-dsblue-100"
                >
                  Confirm Password
                  <p className="text-red-500 pl-1 text-sm">*</p>
                </label>
                <input
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  value={user.confirmPassword}
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md p-2"
                  onChange={onInputChange}
                  onBlur={validateInput}
                />
                <div
                  className={
                    "text-red-600 font-Gilroy-l-itl bg-red-100 mt-2 pl-2 rounded-lg"
                  }
                >
                  {inputError.confirmPassword && (
                    <span className="">{inputError.confirmPassword}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="pt-8">
              {/* Next */}
              <div className="flex justify-end">
                <button
                  type="submit"
                  disabled={disableNext()}
                  className={
                    disableNext()
                      ? "inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-violet-100 hover:bg-dsblue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 grayscale cursor-not-allowed"
                      : "inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-violet-100 hover:bg-dsblue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 "
                  }
                >
                  Next
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
